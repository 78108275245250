<template>
  <van-dialog v-model="show" :width="px2rem(350)" :showConfirmButton="false">
    <div class="content">
      <div class="head">
        <img src="./../../assets/images/index/morelimitIcon.png" alt="">
        <span>历史限时预定商品</span>
      </div>


      <div class="goodsList">
        <goods-item :show-tag="false" :width="px2rem(165)" :products="products"/>
      </div>

    </div>
    <van-icon class="closeIcon" @click="show = false" :size="px2rem(26)" name="close" color="#fff"/>
  </van-dialog>
</template>

<script>
import goodsItem from '../../components/historyGoodsItem.vue'
import Vue from 'vue'
export default {
  components: {goodsItem},
  name: "MoreLimitGoods",
  data() {
    return {
      show: false,
      products:[],
    }
  },

  methods: {
    showPop(lmId,schoolid) {
      this.show = true
      this.$http.get(this.baseProductUrl + `/h5_product/queryHistoryTimeLimitProduct/${lmId}/${Vue.prototype.deptId}/${schoolid}`).then(res => {
        this.products = res.data;
      })
    }
  }
}

</script>
<style lang="less" scoped>
::v-deep .van-dialog__content {
  height: 404px + 32px + 26px;
  background-color: transparent;
  width: 350px;
}

.van-dialog {
  background-color: transparent;
}

.closeIcon {
  position: absolute;
  bottom: 0;
  z-index: 33;
  left: 50%;
  transform: translateX(-50%);
}

.content {
  width: 350px;
  background-color: #fff;
  height: 404px;
  border-radius: 8px;
  position: relative;

  .head {
    height: 40px;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 19px;
      width: 19px;
      margin-right: 6px;
    }

    span {
      font-size: 15px;
      font-weight: 500;
      color: #222222;
      line-height: 15px;
    }
  }

  .goodsList {
    height: 404px - 40px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 6px;
  }
}
</style>
