<template>
    <div class="pageFooter">
        <p><img src="./../assets/images/logo.png" alt=""><span>骄阳服务提供技术支持</span></p>
        <p>Copyright©2016~2022</p>
    </div>
</template>

<script>
export default {
    name: "PageFooter"
}

</script>
<style lang="less" scoped>
.pageFooter {
    padding: 10px 0;
}
p:first-child {
    text-align: center;
    img {
       width: 11px;
       height: 9px;
        margin-right: 4px;
    }

    span {
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
        color: #999999;
    }
}

p:last-child {
    text-align: center;
    font-size: 9px;
    line-height: 1;
    font-weight: 400;
    color: #C1C1C1;
    margin-top: 5px;
}
</style>