<template>
  <div :class="{goodsItem: true, cross: type === 'cross'}"
       v-show="null===product.studentNameList || product.studentNameList
      && product.studentNameList.length>0
      && product.studentNameList.includes(studentInfo.name)"
       @click="$router.push(`/goodsDetail/${product.id}`)">

    <div class="imgBox">
      <van-image :src="product.mainPic" alt=""/>

      <div class="time" v-show="product.libckflag ===0">
        <span>距结束还剩</span>
        <van-count-down  :time="product.time" format="DD天HH:mm:ss"/>
      </div>
    </div>
    <div class="goodsInfo">
      <div class="goodsName van-multi-ellipsis--l2">
        <span class="ys" v-show="product.libckflag ===0">预售</span>
        <span class="xh" v-show="product.libckflag ===1">现货</span>
        {{ product.name }}
      </div>

      <div class="tagList">
        <div class="tag van-hairline--top" v-show="product.deliveryMode ==='2'">配送到校</div>
<!--        <div class="tag" v-show="product.libckflag ===0">预售完生产</div>-->
        <div class="tag van-hairline--top" v-show="product.deliveryMode ==='1'">快递到家</div>
        <div class="tagqh van-hairline--top" v-if="notAvailable" style="display: inline-block">缺货</div>
      </div>


      <div class="handle">
        <div class="price">
          <span>¥</span>
          <span>{{ product.price }}</span>
        </div>

        <div class="btn" @click.stop="$router.push(`/goodsDetail/${product.id}`)">立即购买</div>
<!--        <div class="btn" @click="$router.push({path:'/buyMustSee',query:{product:product}})">立即购买</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {transferDate} from "../util/util";
import Vue from "vue";

export default {
  name: "GoodsItem",

  props: {
    type: {
      type: String,
      default: 'vertical' //cross

    },
    product: {
      type: Object,
      "default": {},
    },
    studentInfo: {
      type: Object,
      "default": {},
    },
    width: {
      type: String,
      default: '4.58667rem'
    }
  },
  watch: {
    product:{
      handler(val){
        let value = val;
        value.time = transferDate(new Date(val.lmtime.replace(/-/g, "/"))).getTime() - Date.now();
        this.product = value;
        this.init();
      },
      immediate:true
    }
  },
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
      notAvailable:false,
    }
  },
  methods:{
    init(){
      this.$http.get(`/product/h5_product/queryReleasePackageCombinationSingleProduct/${this.product.id}/${Vue.prototype.deptId}/${this.product.libckflag}`).then(res => {
        let data = res.data;
        let count = 0;
        data.forEach(item=>{
          item.listMxSubProduct.forEach(sku=>{
            count+=sku.marketable
          });
        })
        if(count===0){
          this.notAvailable = true;
        }
      })
    }
  }
}

</script>
<style lang="less" scoped>


.goodsItem {
  border-radius: 8px;
  width: 172px;
  background-color: #fff;
  margin-top: 10px;
  padding-bottom: 1px;
  &.cross {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0 5px 10px;

    .imgBox {
      margin-right: 7px;

      img {
        border-radius: 4px;
        height: 116px;
        width: 139px;
      }

      .time {
        font-size: 10px;
        padding: 0 7px;
      }

      .van-count-down {
        font-size: 10px;
      }
    }

    .goodsInfo {
      height: 116px;
      padding-top: 8px;
      flex: 1;
      .handle {
        margin-bottom: 0;
      }

      .tagList {
        padding-bottom: 12px;
      }
    }


  }

  .imgBox {
    position: relative;
    font-size: 0;

    .van-image {
      border-radius: 8px 8px 0 0;
      height: 144px;
      width: 172px;
      overflow: hidden;
    }


    .time {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 16px;
      height: 24px;
      background: linear-gradient(-40deg, #F5E4D4, #F9E1D7);
      border-radius: 5px 5px 0px 0px;

      display: flex;
      align-items: center;
      justify-content: space-between;


      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #D53A26;

    }

    .van-count-down {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #D53A26;
    }
  }


  .goodsInfo {
    padding: 7px 11px 0 11px;

    .goodsName {
      font-size: 12px;
      color: #333333;
      line-height: 18px;
      height: 36px;

      .ys {
        width: 32px;
        //background: linear-gradient(264deg, #4DEECB, #32D5B4);
        background: linear-gradient(264deg, #f58130, #ef2514);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #F4F4F4;
        line-height: 12px;
        text-align: center;
        padding: 2px 0;
        display: inline-block;
        margin-right: 3px;

      }

      .xh {
        width: 32px;
        background: linear-gradient(264deg, #4db3ee, #00A0E9);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #F4F4F4;
        line-height: 12px;
        text-align: center;
        padding: 2px 0;
        display: inline-block;
        margin-right: 3px;

      }
    }


    .tagList {
      padding: 5px 0 5px 0;

      .tag {
        border: 1px solid #FF0000;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 10px;
        font-weight: 400;
        color: #FF0000;
        line-height: 10px;
        display: inline-block;
        margin-right: 5px;

        &.plain {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          border: none;
        }
      }
      .tagqh{
        border: 1px solid #363636;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 10px;
        font-weight: 400;
        color: #343434;
        line-height: 10px;
        display: inline-block;
        margin-right: 5px;

        &.plain {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          border: none;
        }
      }
    }

    .handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 27px;
      margin-bottom: 10px;

      .price {
        color: #D53A26;
        font-weight: 500;

        span:first-child {
          font-size: 12px;
          margin-right: 3px;
          font-weight: bold;
        }

        span:last-child {
          font-size: 19px;
          font-weight: bold;
        }
      }

      .btn {
        width: 65px;
        height: 20px;

        background: #DE3926;
        border-radius: 100px;

        font-size: 11px;
        font-weight: 400;
        color: #fff;

        line-height: 20px;
        text-align: center;

      }
    }
  }
}
</style>
