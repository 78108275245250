<template>
<div  class="singleStudent">
  <van-row>
    <div class="leftAvatar">
      <img :src="null | Avatar" alt="">
    </div>
    <div class="rightContent">
      <div class="rightName">
        <span>{{ nameDesensitization(student.receiverName) }}</span>
        <span @click="addNewStudent" class="rightIcon">切换 <van-icon name="arrow" /></span>
      </div>
      <div class="rightSchool"> {{ student.school }}   {{ student.grade }}   {{ student.classn }}</div>
    </div>
  </van-row>
</div>
</template>
<script>
import {desensitizeName} from "../../util/util";

export default {
  name: "singleStudent",
  data() {
    return {
      student:{},
    }
  },
  props:{
    studentInfo:{
      "default": {},
      type:Object
    }
  },
  watch:{
    studentInfo: {
      handler(val) {
        this.student ={
          photo: val.photo,
          receiverName: val.receiverName,
          grade: val.grade,
          classn: val.classn,
          classId:val.classId,
          gradeId:val.gradeId,
          phone: val.phone,
          school: val.school,
          id:val.id,
          schoolid:val.schoolid,
        };
      },
      immediate: true,
    }
  },
  methods:{
    addNewStudent() {
      this.$router.push({path: '/student/newStudentList'})
    },
    nameDesensitization(name){
      return desensitizeName(name);
    }
  }
}
</script>



<style scoped lang="less">
.singleStudent{
  background:#FFFFFF;
  margin:3%;
  border-radius: 5px;
}
.leftAvatar{
  width: 30px;
  border-radius: 50%;
  background: #FFFFFF;
  margin:10px;
  img{
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  display: inline-block;
}
.rightContent{
  display: inline-block;
  line-height: 20px;
  margin-top:3%;
}
.rightName{
  color:#333333;
  font-size: 14px;
  font-weight: bold;
  //  字体
  font-family: SourceHanSansCN-Bold;
}

.rightSchool{
  color:#999999;
  font-family: SourceHanSansCN-Regular;
  font-size: 12px;
}

.rightIcon{
  background: #2ACC4C;
  font-size: 10px;
  color: #fcfafa;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  padding-left: 5px;
  margin-left: 5px;
}
</style>
