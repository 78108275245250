<template>
  <!-- /**
   * long description for the file
   *
   * @summary 大致跟GoodsItem一样, 不过这个用于首页弹窗里面的， 有挺多地方不一样，而且我哦又不太清楚需求， 干脆新建一个，专门用于服务弹窗
   * @author LinRenChen
   *
   * Created at     : 2022-06-10 04:17:44
   * Last modified  : 2022-06-18 21:29:31
   */ -->
  <div :style="{width: width}" :class="{goodsItem: true, cross: type === 'cross'}">
    <div v-for="(item,index) in products" :key="index">
      <div class="imgBox">
        <!--      <img src="https://tse2-mm.cn.bing.net/th/id/OIP-C.G_1skEngP2toppemLeGvKgHaGW?pid=ImgDet&rs=1" alt="">-->
        <img :src="item.mainPic" alt="">
        <div class="time">
          <span>最近预定于{{ item.lmtime }}结束</span>
        </div>
      </div>
      <div class="goodsInfo">
        <div class="goodsName van-multi-ellipsis--l2">
          <!-- <span class="ys">预售</span> -->
          <!--        长沙市一中夏季校服两件男女同款-->
          {{ item.name }}
        </div>


        <div class="handle">
          <div class="price">
            <span>¥</span>
            <span>{{ item.price }}</span>
          </div>
        </div>
        <div style="font-size: 10px;color:#999999">下轮预定请关注通知</div>
        <!--        <div class="btn">
                  我要补购
                </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "GoodsItem",

  props: {
    type: {
      type: String,
      default: 'vertical' //cross

    },
    products: {
      type: Array,
      "default": [],
    },
    width: {
      type: String,
      default: '4.58667rem'
    },

  },
  watch: {
    products: {
      handler(val) {
        val.forEach(product => {
          let date = new Date(product.lmtime);
          product.lmtime = moment(date).format('MM-DD')
        });
        this.products = val
      },
      immediate: true
    }
  },
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
    }
  }
}

</script>
<style lang="less" scoped>


.goodsItem {
  border-radius: 8px;
  background-color: #fff;
  margin-top: 10px;
  padding-bottom: 2px;
  // &.cross {
  //     display: flex;
  //     align-items: center;
  //     width: 100%;
  //     padding: 5px 0 5px 10px;
  //     .imgBox {
  //         margin-right: 7px;
  //         img {
  //             border-radius:4px;
  //             height: 116px;
  //             width: 139px;
  //         }
  //     }
  //     .goodsInfo {
  //         height: 116px;
  //         padding-top: 8px;
  //         .handle {
  //             margin-bottom: 0;

  //         }
  //         .tagList {
  //             padding-bottom: 12px;
  //         }
  //     }


  // }

  .imgBox {
    position: relative;
    font-size: 0;
    height: 138px;
    width: 100%;

    img {
      border-radius: 8px 8px 0 0;
      height: 138px;
      width: 100%;
    }


    .time {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      height: 23px;
      background: rgba(49, 49, 49, .5);
      border-radius: 5px 5px 0px 0px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 7px;


      font-size: 12px;
      font-weight: bold;
      color: #f4f4f4;
      line-height: 12px;
    }
  }


  .goodsInfo {
    padding: 3px 11px 0 11px;

    .goodsName {
      font-size: 12px;
      color: #333333;
      line-height: 17px;

      .ys {
        width: 32px;
        height: 14px;
        background: linear-gradient(264deg, #f58130, #ef2514);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #F4F4F4;

        text-align: center;

        line-height: 14px;
        display: inline-block;
        margin-right: 3px;

      }
    }


    .tagList {
      padding: 5px 0 15px 0;

      .tag {
        border: 1px solid #FF0000;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 10px;
        font-weight: 400;
        color: #FF0000;
        line-height: 1;
        display: inline-block;
        margin-right: 11px;

        &.plain {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          border: none;
        }
      }
    }

    .handle {
      display: flex;
      align-items: center;
      margin-top: 5px;
      height: 27px;
      margin-bottom: 5px;

      .price {
        margin-right: 11px;
        color: #D53A26;
        font-weight: 500;

        span:first-child {
          font-size: 12px;
          font-weight: bold;
        }

        span:last-child {
          font-size: 19px;
          font-weight: bold;
        }
      }

      > span {
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        line-height: 1;
      }

      // .btn {
      //     width: 76px;
      //     height: 27px;
      //     background: #FFECE8;
      //     border-radius:100px;

      //     font-size: 13px;
      //     font-weight: 400;
      //     color: #D53A26;

      //     line-height: 27px;
      //     text-align: center;

      // }
    }


    .btn {
      background: #FFD8DB;
      border-radius: 5px;

      font-size: 10px;
      font-weight: 400;
      color: #D74633;

      line-height: 1;
      display: inline-block;
      padding: 5px 7px;
    }
  }
}
</style>
